<template>
  <section class="invoice-preview-wrapper">
    <b-alert
        variant="danger"
        :show="!isFatura"
    >
      <h4 class="alert-heading">
        {{ errorText }}
      </h4>
    </b-alert>
    <b-row
        class="invoice-preview"
        v-if="isFatura"
        ref="faturaContent"
    >
      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            no-body
            class="invoice-preview-card"
        >
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <b-col cols="12" class="d-flex justify-content-between">
                <h3>
                  Proforma Fatura
                </h3>
                <h4>
                  Proforma ID
                  <span class="invoice-number"> #{{ fatura.faturaOzeti.proformaID }}</span>
                </h4>
              </b-col>
            </b-row>
            <hr/>
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <h4 class="card-text mb-25 font-weight-bold cursor-pointer text-success"
                    @click="$router.push({name:'cariDetay',params:{id:fatura.faturaOzeti.cariKartID}})"
                >
                  {{ fatura.faturaOzeti.faturaUnvan }}
                </h4>
                <p class="card-text mb-25 font-weight-bold">
                  {{ fatura.faturaOzeti.adi }} {{ fatura.faturaOzeti.soyAdi }}
                </p>
                <p class="card-text mb-0">
                  Tel: {{ fatura.faturaOzeti.telefon }}
                </p>
                <p class="card-text mb-0">
                  E-Posta: {{ fatura.faturaOzeti.ePosta }}
                </p>
                <p class="card-text mb-0">
                  Vergi Dairesi: {{ fatura.faturaOzeti.vergiDairesi }}
                </p>
                <p class="card-text mb-0">
                  Vergi Numarası: {{ fatura.faturaOzeti.vergiNumarasi }}
                </p>
              </div>
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  <b-badge variant="light-success">
                    Satış Faturası
                  </b-badge>
                </h4>
              </div>
            </div>
          </b-card-body>
          <hr class="invoice-spacing">
          <b-card-body
              class="invoice-padding pt-0"
          >
            Proforma Açıklaması: {{ fatura.faturaOzeti.aciklama }}
          </b-card-body>
          <b-table-lite
              striped
              hover
              id="fatura-detay"
              responsive
              :items="fatura.faturaBilgileri.birimData"
              :fields="fields"
          >
            <template #cell(MalHizmet)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.urunAdi }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template>
            <template #cell(birimFiyat)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{
                  Number(data.item.tutar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }} TL
              </b-card-text>
            </template>
            <template #cell(toplamTutar)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{
                  Number(data.item.toplamTutar)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }} TL
              </b-card-text>
            </template>
            <template #cell(0015)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ gelirOptimize(data.item.vergiData.vergiListesi, '0015') }}%
              </b-card-text>
            </template>
            <template #cell(0040)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ gelirOptimize(data.item.vergiData.vergiListesi, '0040') }}%
              </b-card-text>
            </template>
            <template #cell(0059)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ gelirOptimize(data.item.vergiData.vergiListesi, '0059') }}%
              </b-card-text>
            </template>
            <template #cell(4080)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ gelirOptimize(data.item.vergiData.vergiListesi, '4080') }}%
              </b-card-text>
            </template>
            <template #cell(0021)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ gelirOptimize(data.item.vergiData.vergiListesi, '0021') }}%
              </b-card-text>
            </template>
          </b-table-lite>
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
              >
              </b-col>
              <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Ara Toplam:
                    </p>
                    <p class="invoice-total-amount" v-if="fatura.faturaBilgileri.araToplam">
                      {{
                        Number(fatura.faturaBilgileri.araToplam)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Toplam Vergi:
                    </p>
                    <p class="invoice-total-amount" v-if="fatura.faturaBilgileri.toplamVergi">
                      {{
                        Number(fatura.faturaBilgileri.toplamVergi)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </p>
                  </div>
                  <div class="invoice-total-item" v-for="(vergi ,i) in fatura.faturaBilgileri.vergiData" :key="i">
                    <p class="invoice-total-title">
                      {{ vergi.vergiAdi }} <small>{{ vergi.vergiOrani }}% </small>
                    </p>
                    <p class="invoice-total-amount" v-if="vergi.toplamVergi">
                      {{
                        Number(vergi.toplamVergi)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Toplam Tutar:
                    </p>
                    <p class="invoice-total-amount" v-if="fatura.faturaBilgileri.toplamTutar">
                      {{
                        Number(fatura.faturaBilgileri.toplamTutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }} TL
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <hr class="invoice-spacing">
        </b-card>
      </b-col>
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
          ref="faturaIslemleri"
      >
        <b-card>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              @click="convertSatisFaturasi"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
              v-if="fatura.faturaOzeti.kayitID == '0'"
          >
            Satış Faturasına Dönüştür
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="printAl"
              block
              :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important',border:'none'}"
          >
            Yazdır
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              block
              :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none',opacity: fatura.faturaOzeti.kayitID != '0' ? '0.4':'1' }"
              @click="fatura.faturaOzeti.kayitID == '0' ? proformaSil() :''"
              v-b-tooltip="fatura.faturaOzeti.kayitID != '0' ?'Aktarılan Proforma Silinemez':''"
          >
            Sil
          </b-button>
          <hr/>
        </b-card>
      </b-col>
    </b-row>
    <invoice-sidebar-send-invoice/>
    <invoice-sidebar-add-payment/>
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBModal,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BTab,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from './faturaComponents/InvoiceSidebarAddNewCustomer.vue'
import InvoiceSidebarSendInvoice from './faturaComponents/InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from './faturaComponents/InvoiceSidebarAddPayment.vue'
import { $themeConfig } from '@themeConfig'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    flatPickr,
    ModelListSelect,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BTab,
    BTabs,
    BModal,
    BDropdown,
    BDropdownItem,
    BBadge,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  setup() {
    const { invoiceLogo } = $themeConfig.app
    const paymentDetails = ref({})

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      paymentDetails,
      printInvoice,
      invoiceLogo,
    }
  },
  data() {
    return {
      bakiye: 0,
      isFatura: true,
      errorText: '',
      id: this.$route.params.id,
      fields: [
        {
          key: 'urunAdi',
          label: 'Mal / HizmetAdı',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'adet',
          label: 'Miktar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'birim',
          label: 'Birim',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'birimFiyat',
          label: 'B. Fiyat',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'toplamTutar',
          label: 'Toplam Tutar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      tahFields: [
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'kasaID',
          label: 'girisTuru',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      sTahsilat: {
        islemNotu: '',
        tutar: '',
        fisNo: '',
        tahsilat: {
          title: 'Nakit',
          key: 1,
        },
        tahsilatSekli: [
          {
            title: 'Nakit',
            key: 1,
          },
          {
            title: 'Kredi Kartı',
            key: 2,
          },
          {
            title: 'Havale',
            key: 3,
          },
        ],
      },
      cekTahsilat: {
        islemNotu: '',
        tutar: '',
        seriNo: '',
        duzenlenmeTarihi: '',
        tahsilatTarihi: '',
        isim: '',
      },
    }
  },
  computed: {
    gelirOptimize(val) {
      return (salut, vergiKodu) => {
        if (salut) {
          const veri = Object.values(salut)
              .find(x => x.vergiKodu === vergiKodu)
          return veri?.vergiOrani || 0
        }
        return 0
      }
    },
    vergiListesi() {
      return store.getters.VERGI_LISTESI_GET
    },
    fatura() {
      return store.getters.PROFORMA_DETAY_GET
    },
  },
  methods: {
    proformaSil() {
      this.$swal({
        title: 'Proformayı Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              let id = this.id
              store.dispatch('proformaSil', { id })
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status == 200) {
                      this.$router.push('/proformalar')
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Başarılı!',
                          text: res.result.errMsg,
                          icon: 'ThumbsDownIcon',
                          variant: 'success',
                        },
                      }, { position })
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
            }
          })
    },
    convertSatisFaturasi() {
      store.state.isProformaFatura = true
      router.push({
        name: 'proformaToFatura',
        params: { id: this.id },
      })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'fatura')
      fd.append('methodName', 'faturaKaydet')
      fd.append('faturaData[faturaTuru]', 1)
      fd.append('proformaID', this.id) // todo proformaID satış faturasına dönüştürmek için kullanılır
      fd.append('faturaData[cariKartID]', this.fatura.faturaOzeti.cariKartID)
      fd.append('faturaData[aciklama]', this.fatura.faturaOzeti.aciklama)
      Object.values(this.fatura.faturaBilgileri.birimData)
          .forEach((item, index) => {
            fd.append(`faturaData[birimListesi][${index}][urunAdi]`, item.urunAdi || '')
            fd.append(`faturaData[birimListesi][${index}][adet]`, item.adet || 0)
            fd.append(`faturaData[birimListesi][${index}][birim]`, item.birim || 'Adet')
            fd.append(`faturaData[birimListesi][${index}][tutar]`, item.toplamTutar || 0)
            fd.append(`faturaData[birimListesi][${index}][stokID]`, item.stokID || '')
            Object.entries(item.vergiData.vergiListesi)
                .forEach((vergi, i) => {
                  fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiKodu]`, Object.values(vergi)[1].vergiKodu)
                  fd.append(`faturaData[birimListesi][${index}][vergiListesi][${i}][vergiOrani]`, Object.values(vergi)[1].vergiOrani || 0)
                })
          })
      return fd
    },
    eFaturaGet() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'fatura')
      fd.append('methodName', 'eFaturaDetay')
      fd.append('faturaID', this.fatura.faturaOzeti.faturaID)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            const Y = '<html>'
            const X = res.data
            var Z = X.slice(X.indexOf(Y) + Y.length)
            var myWindow = window.open(
                'about:blank',
                'print_popup',
                'width=1000,height=800',
            )
            myWindow.document.write(Z)
            myWindow.print()
            const mesaj = this.$store.getters.notificationSettings(res.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    eFaturaAktar() {
      this.$refs.faturaContent.showLoading = true
      const id = this.id
      store.dispatch('eFaturaAktar', id)
          .then((res, position = 'bottom-right') => {
            this.$refs.faturaContent.showLoading = false
            if (res.result.status == 200) {
              store.dispatch('faturaDetay', { id })
                  .then(res => {
                    if (res.result.status == 200) {
                      this.bakiye = res.bakiye
                    }
                  })
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı!',
                    text: res.result.errMsg,
                    icon: 'ThumbsDownIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.$refs.faturaContent.showLoading = false
            if (err.result.errMsg) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: err.result.errMsg,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    printAl() {
      window.print()
    },
  },
  created() {
    let id = this.id
    store.dispatch('proformaDetay', { id })
        .then(res => {
          if (res.result.status != 200) {
            this.isFatura = false
            this.errorText = res.result.errMsg
          } else {
            this.bakiye = res.bakiye
          }
        })
    store.dispatch('vergiListesi')
        .then(res => {
          if (this.fatura.faturaBilgileri.vergiData) {
            Object.entries(this.fatura.faturaBilgileri.vergiData)
                .forEach(entry => {
                  const [key, value] = entry
                  const haveVergiKey = key.substring(key.indexOf('-') + 1)
                  const match = Object.values(res)
                      .find(x => x.vergiKodu === haveVergiKey)
                  if (match) {
                    this.fields.push({
                      key: match.vergiKodu,
                      label: match.vergiAdi,
                      sortable: true,
                      tdClass: 'vergiFields',
                      thStyle: {
                        backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
                        color: 'white',
                        border: 'none',
                      },
                    })
                  }
                })
          }
        })
  },
  beforeDestroy() {
    store.commit('PROFORMA_DETAY_ACTION', 'remove')
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.vergiFields {
  display: none !important;
}

</style>
<style lang="scss">

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.tahsilat-table .b-table tbody tr:hover td:first-child {
  border-left: none;
}

.table th, .table td {
  padding: 0.42rem 0.7rem !important;
}
</style>
